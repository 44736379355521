import Vue from 'vue'
import VueI18n from 'vue-i18n'
import deJson from '@/libs/i18n/locales/de.json'
import enJson from '@/libs/i18n/locales/en.json'
import frJson from '@/libs/i18n/locales/fr.json'
import ptJson from '@/libs/i18n/locales/pt.json'
import ruJson from '@/libs/i18n/locales/ru.json'

Vue.use(VueI18n)
function loadLocaleMessages() {
  const locales = {de: deJson, en: enJson, fr: frJson, pt: ptJson, ru: ruJson}
  const messages = {}
  Object.keys(locales).forEach(key => messages[key] = locales[key])
  return messages
}
export default new VueI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  messages: loadLocaleMessages(),
})
