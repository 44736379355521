import state from '@/store/settings/moduleSettingsState.js'
import mutations from '@/store/settings/moduleSettingsMutations.js'
import actions from '@/store/settings/moduleSettingsActions.js'
import getters from '@/store/settings/moduleSettingsGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
