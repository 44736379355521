import state from '@/store/reports/moduleReportsState.js'
import mutations from '@/store/reports/moduleReportsMutations.js'
import actions from '@/store/reports/moduleReportsActions.js'
import getters from '@/store/reports/moduleReportsGetters.js'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
