import profmanHealthFaviconSrc from '@/assets/images/customization/profman-health-favicon.png'
import profmanHealthLogoSrc from '@/assets/images/customization/profman-health-logo.png'
import profmanHealthLogoHorizontalSrc from '@/assets/images/customization/profman-health-logo-horizontal.png'
import profmanDentalFaviconSrc from '@/assets/images/customization/profman-dental-favicon.png'
import profmanDentalLogoSrc from '@/assets/images/customization/profman-dental-logo.png'
import profmanDentalLogoHorizontalSrc from '@/assets/images/customization/profman-dental-logo-horizontal.png'

// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// APP CONFIG
export const $themeConfig = {
  app: {
    // profman-health config
    appNameProfmanHealth: 'ProfmanHealth',
    appFaviconProfmanHealth: profmanHealthFaviconSrc,
    appLogoProfmanHealth: profmanHealthLogoSrc,
    appLogoHorizontalProfmanHealth: profmanHealthLogoHorizontalSrc,
    // profman-dental config
    appNameProfmanDental: 'ProfmanDental',
    appFaviconProfmanDental: profmanDentalFaviconSrc,
    appLogoProfmanDental: profmanDentalLogoSrc,
    appLogoHorizontalProfmanDental: profmanDentalLogoHorizontalSrc,
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'horizontal', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: '', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static', // static, sticky, hidden
    },
    customizer: false,
    enableScrollToTop: true,
  },
}
