import chatConstants from '@/constants/chatConstants'
import routerConstants from '@/constants/routerConstants'
import patientAppointmentConstants from '@/constants/patientAppointmentConstants'
import roleConstants from '@/constants/roleConstants'
import patientCalendarConstants from '@/constants/patientCalendarConstants'
import leadConstants from '@/constants/leadConstants'
import dentalLaboratoryPurchaseOrderConstants from '@/constants/dentalLaboratoryPurchaseOrderConstants'
import userConstants from '@/constants/userConstants'
import dentalServiceConstants from '@/constants/dentalServiceConstants'
import treatmentPlanConstants from '@/constants/treatmentPlanConstants'
import materialConstants from '@/constants/materialConstants'
import integratorConstants from '@/constants/integratorConstants'
import actConstants from '@/constants/actConstants'
import employeeConstants from '@/constants/employeeConstants'
import revenueConstants from '@/constants/revenueConstants'
import treatmentDiaryConstants from '@/constants/treatmentDiaryConstants'
import dentalServiceGroupConstants from '@/constants/dentalServiceGroupConstants'
import treatmentPresentationConstants from '@/constants/treatmentPresentationConstants'

export default {
  chat: chatConstants,
  router: routerConstants,
  patientAppointment: patientAppointmentConstants,
  role: roleConstants,
  patientCalendar: patientCalendarConstants,
  lead: leadConstants,
  dentalLaboratoryPurchaseOrder: dentalLaboratoryPurchaseOrderConstants,
  user: userConstants,
  dentalService: dentalServiceConstants,
  dentalServiceGroup: dentalServiceGroupConstants,
  treatmentPlan: treatmentPlanConstants,
  material: materialConstants,
  integrator: integratorConstants,
  act: actConstants,
  employee: employeeConstants,
  revenue: revenueConstants,
  treatmentDiary: treatmentDiaryConstants,
  treatmentPresentation: treatmentPresentationConstants
}
