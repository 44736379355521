<template>
  <div
    class="toastification-system"
    @click.stop.prevent
  >
    <div class="toastification-system__content">
      <div class="toastification-system__header">
        <b-img
          :src="programLogoSrc"
          height="34px"
        ></b-img>
        <div class="toastification-system__header_title">
          <div class="d-flex flex-column justify-content-end mr-50">
            <span class="font-weight-bold text-right">{{ userFirstName }}</span>
            <span class="font-weight-bold">&#128075; Приветствую вас!</span>
          </div>
          <b-img
            :src="assistantSrc"
            height="34px"
          ></b-img>
        </div>
      </div>
      <hr class="w-100">
      <div class="d-flex align-items-center flex-column justify-content-center">
        <div class="toastification-system__body">
          <div
            class="mb-1 toastification-system__body-title"
            v-text="title"
          ></div>
          <div class="mb-1 text-body toastification-system__body-text">{{ text }}</div>
          <template v-if="files.length">
            <div class="d-flex text-body flex-column mb-1">
              <div class="font-weight-bold">Файлы:</div>
              <div
                v-for="(f, idx) in files"
                :key="idx"
                class="toastification-system__body-file"
                @click="downloadFile(f)"
              >
                <a class="font-small-1">
                  <feather-icon
                    icon="FileIcon"
                    size="14"
                  ></feather-icon>
                  <span class="ml-25">{{ f.name }}</span>
                </a>
              </div>
            </div>
          </template>
          <b-button
            variant="primary"
            @click="$emit('click-toast')"
          >Ок</b-button>
        </div>
        <hr class="w-100">
        <div>
          С уважением, ваш {{ programVersion }} ассистент! &#x1F642;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BImg, BLink, BButton } from 'bootstrap-vue'
import { getFirstName, getProgramVersionLogo, getProgramVersionName } from '@/composables/currentUser'
import { mixins } from '@/mixins'
import assistantSrc from '@/assets/images/icons/assistant.svg'

export default {
  name: 'ToastificationCustom',
  components: {
    BImg, BAvatar, BLink, BButton
  },
  emits: ['click-toast'],
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    files: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  setup() {
    const userFirstName = mixins.methods.capitalizeFirstLetter(getFirstName())
    const programLogoSrc = getProgramVersionLogo()
    const programVersion = getProgramVersionName()

    const downloadFile = file => {
      if (file) {
        const fileUrl = file.src
        const fileLink = document.createElement('a')
        fileLink.href = fileUrl
        fileLink.target = '_blank'
        fileLink.download = file.name
        document.body.appendChild(fileLink)
        fileLink.click()
      }
    }

    return {
      userFirstName,
      programLogoSrc,
      programVersion,
      assistantSrc,

      downloadFile
    }
  }
}
</script>

<style lang='scss' scoped>

@import '@/assets/scss/variables/variables';

.toastification-system {
  display: grid;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header_title {
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 13px;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__body {
    width: 100%;
    display: grid;
  }

  &__body-title {
    margin-top: 0;
    font-size: 1.5rem;
    font-weight: inherit;
    color: #5e5873;
  }

  &__body-text {
    font-size: 13px;
    text-align: justify;
  }

  &__body-file {
    display: flex;
    align-items: center;
    margin-top: .25rem;

    &:hover {
      text-decoration: underline;
    }

    a {
      color: $primary;
    }
  }
}

.dark-layout {
  .toastification-system {
    &__body-title, &__body-text {
      color: #ffffff !important;
    }
  }
}

</style>
