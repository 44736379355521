import state from '@/store/employee/moduleEmployeeState.js'
import mutations from '@/store/employee/moduleEmployeeMutations.js'
import actions from '@/store/employee/moduleEmployeeActions.js'
import getters from '@/store/employee/moduleEmployeeGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
