import state from '@/store/analytics/moduleAnalyticsState.js'
import mutations from '@/store/analytics/moduleAnalyticsMutations.js'
import actions from '@/store/analytics/moduleAnalyticsActions.js'
import getters from '@/store/analytics/moduleAnalyticsGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
