import state from '@/store/dental-laboratory/moduleDentalLaboratoryState'
import mutations from '@/store/dental-laboratory/moduleDentalLaboratoryMutations'
import actions from '@/store/dental-laboratory/moduleDentalLaboratoryActions'
import getters from '@/store/dental-laboratory/moduleDentalLaboratoryGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
