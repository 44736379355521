import store from '@/store'
import { getIntegratorId } from '@/composables/currentUser'

export default function useIntegrator() {
  const getCurrentIntegrator = async () => {
    const integratorId = getIntegratorId()
    if (integratorId) {
      return await getIntegratorById(integratorId)
    }
  }
  const getIntegratorById = async integratorId => await store.dispatch('info/getIntegrator', integratorId)

  return {
    getCurrentIntegrator
  }
}