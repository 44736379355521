import state from '@/store/notification/moduleNotificationState.js'
import mutations from '@/store/notification/moduleNotificationMutations.js'
import actions from '@/store/notification/moduleNotificationActions.js'
import getters from '@/store/notification/moduleNotificationGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
