import state from '@/store/revenue/moduleRevenueState.js'
import mutations from '@/store/revenue/moduleRevenueMutations.js'
import actions from '@/store/revenue/moduleRevenueActions.js'
import getters from '@/store/revenue/moduleRevenueGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
