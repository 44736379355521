import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from '@/store/app'
import appConfig from '@/store/app-config'
import verticalMenu from '@/store/vertical-menu'
import auth from '@/store/auth/moduleAuth'
import info from '@/store/info/moduleInfo'
import patient from '@/store/patient/modulePatient'
import todo from '@/store/todo/moduleTodo'
import application from '@/store/application/moduleApplication'
import schedule from '@/store/schedule/moduleSchedule'
import employee from '@/store/employee/moduleEmployee'
import reports from '@/store/reports/moduleReports'
import revenue from '@/store/revenue/moduleRevenue'
import analytics from '@/store/analytics/moduleAnalytics'
import notification from '@/store/notification/moduleNotification'
import wage from '@/store/wage/moduleWage'
import chat from '@/store/chat/moduleChat'
import lead from '@/store/lead/moduleLead'
import settings from '@/store/settings/moduleSettings'
import serviceCalculation from '@/store/service-calculation/moduleServiceCalculation'
import dentalLaboratory from '@/store/dental-laboratory/moduleDentalLaboratory'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    info,
    patient,
    todo,
    schedule,
    application,
    employee,
    revenue,
    reports,
    analytics,
    notification,
    wage,
    chat,
    lead,
    settings,
    serviceCalculation,
    dentalLaboratory
  },
  strict: process.env.DEV,
})
