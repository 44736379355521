import state from '@/store/wage/moduleWageState.js'
import mutations from '@/store/wage/moduleWageMutations.js'
import actions from '@/store/wage/moduleWageActions.js'
import getters from '@/store/wage/moduleWageGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
