import state from '@/store/lead/moduleLeadState.js'
import mutations from '@/store/lead/moduleLeadMutations.js'
import actions from '@/store/lead/moduleLeadActions.js'
import getters from '@/store/lead/moduleLeadGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
