import state from '@/store/patient/modulePatientState.js'
import mutations from '@/store/patient/modulePatientMutations.js'
import actions from '@/store/patient/modulePatientActions.js'
import getters from '@/store/patient/modulePatientGetters.js'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
