import state from '@/store/todo/moduleTodoState.js'
import mutations from '@/store/todo/moduleTodoMutations.js'
import actions from '@/store/todo/moduleTodoActions.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
