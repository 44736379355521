import state from '@/store/auth/moduleAuthState.js'
import mutations from '@/store/auth/moduleAuthMutations.js'
import actions from '@/store/auth/moduleAuthActions.js'
import getters from '@/store/auth/moduleAuthGetters.js'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
