import store from '@/store'

export default function useTreatmentDiary() {
  const getTreatmentDiary = async treatmentDiaryId => await store.dispatch(
    'patient/getTreatmentDiaryInfo', treatmentDiaryId
  )

  return {
    getTreatmentDiary
  }
}