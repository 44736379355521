import state from '@/store/info/moduleInfoState.js'
import mutations from '@/store/info/moduleInfoMutations.js'
import actions from '@/store/info/moduleInfoActions.js'
import getters from '@/store/info/moduleInfoGetters.js'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
