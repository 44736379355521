import patients from '@/navigation/horizontal/patients'
import schedule from '@/navigation/horizontal/schedule'
import dictionary from '@/navigation/horizontal/dictionary'
import application from '@/navigation/horizontal/application'
import reports from '@/navigation/horizontal/reports'
import todo from '@/navigation/horizontal/todo'
import analyticsReporting from '@/navigation/horizontal/analyticsReporting'
import sendingOutNotifications from '@/navigation/horizontal/sendingOutNotifications'
import wage from '@/navigation/horizontal/wage'
import revenue from '@/navigation/horizontal/revenue'
import lead from '@/navigation/horizontal/lead'
import serviceCalculation from '@/navigation/horizontal/serviceCalculation'
import dentalLaboratory from '@/navigation/horizontal/dentalLaboratory'

// Array of sections
export default [
  ...patients,
  ...lead,
  ...dictionary,
  ...dentalLaboratory,
  ...application,
  ...schedule,
  ...reports,
  ...analyticsReporting,
  ...revenue,
  ...sendingOutNotifications,
  ...wage,
  ...serviceCalculation,
  ...todo,
]
