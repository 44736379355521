import state from '@/store/schedule/moduleScheduleState.js'
import mutations from '@/store/schedule/moduleScheduleMutations.js'
import actions from '@/store/schedule/moduleScheduleActions.js'
import getters from '@/store/schedule/moduleScheduleGetters.js'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
