import Vue from 'vue'
import FeatherIcon from '@/core/components/feather-icon/FeatherIcon.vue'
import vMultiselectListbox from 'vue-multiselect-listbox'
import 'vue-multiselect-listbox/src/scss/vue-multi-select-listbox.scss'
import VueFlatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'flatpickr'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import moment from 'moment'
import { Settings } from 'luxon'

flatPickr.setDefaults({
  locale: Russian,
})

Settings.defaultLocale = 'ru'

moment.locale('ru')

Vue.use('moment', moment)
Vue.use(Datetime)
Vue.use(VueFlatPickr)
Vue.component('v-multiselect-listbox', vMultiselectListbox)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('datetime', Datetime);
