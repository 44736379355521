import state from '@/store/service-calculation/moduleServiceCalculationState'
import mutations from '@/store/service-calculation/moduleServiceCalculationMutations'
import actions from '@/store/service-calculation/moduleServiceCalculationActions'
import getters from '@/store/service-calculation/moduleServiceCalculationGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
