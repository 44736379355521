export default {
    suppliers: state => state.suppliers,
    supplier: state => id => state.suppliers.find(s => s.id === id),
    suppliersCount: state => state.suppliersCount,
    supplierToEdit: state => state.supplierToEdit,

    orders: state => state.orders,
    ordersCount: state => state.ordersCount,
    order: state => state.order,

    products: state => state.products,
    product: state => id => state.products.find(p => p.id === id),
    productsCount: state => state.productsCount,
    productToEdit: state => state.productToEdit,

    bucket: state => state.bucket,
    bucketItem: state => id => state.bucket.find(b => b.id === id),
    bucketItemByProduct: state => product => state.bucket.find(b => b.product_id === product.id),
    emailSettings: state => state.emailSettings,
    orderStatuses: state => state.orderStatuses,
    orderStatus: state => status => {
        return state.orderStatuses[status]
    },
    // BVModal
    bvModal: state => state.bvModal,
    // Export
    exportProcessActive: state => state.exportProcessActive,
    // State table
    stateTableNeedRefresh: state => state.stateTableNeedRefresh,
}
