import state from '@/store/application/moduleApplicationState.js'
import mutations from '@/store/application/moduleApplicationMutations.js'
import actions from '@/store/application/moduleApplicationActions.js'
import getters from '@/store/application/moduleApplicationGetters.js'

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
